









import { defineComponent } from '@vue/composition-api';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import GcButton from '@/components/primitives/GcButton.vue';
import RestoreAlert from '@/components/RestoreAlert.vue';
export default defineComponent({
  components: {
    GcButton,
    RestoreAlert,
  },
  setup: (_, { emit }) => {
    const startAssistant = () => {
      emit('start');
    };

    return {
      faAngleRight,
      startAssistant,
    };
  },
});
