















































import { defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
    GcAlert,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props) => {
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const sentSuccessfully = ref(false);

    const onSend = () => {
      sentSuccessfully.value = true;
    };

    return {
      formData,
      formValid,
      onSend,
      sentSuccessfully,
    };
  },
});
