/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description: '',
    title: 'g|c Assistent',
    step: {
      label: 'Start',
    },
  },
  contactForm: {
    formFields: {
      note: {
        label: 'Wie können wir Ihnen helfen?',
      },
      privacyConfirmation: {
        component: {
          label:
            'Hiermit stimme ich zu, dass meine hier eingegebenen  Daten zum Zweck der Kontaktaufnahme mit der TIWAG-Tiroler Wasserkraft AG übermittelt, verarbeitet und gespeichert werden.',
        },
      },
      name: {
        label: 'Name',
      },
    },
    sentSuccessfully:
      'Vielen Dank für Ihre Nachricht. Wir werden uns umgehend mit Ihnen in Verbindung setzen.',
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
