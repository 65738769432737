import component from './ContactForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { AssistantContext } from '~/steps/steps';
import {
  buildFormCheckbox,
  buildFormTextArea,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { sendMail } from '@/lib/submit/send-mail';
import { getMarkdownData } from '@/lib/formatter/context';

export interface ContactFormStepContext extends AssistantContext {
  name: FormTextFieldValueType;
  email: FormTextFieldValueType;
  note: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const config: StepDefinition = {
  component,
  fields: [
    buildFormTextField('name', {
      required: true,
    }),

    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormTextArea('note', {
      required: true,
    }),
    buildFormCheckbox('privacyConfirmation', {
      hideLabel: true,
      required: true,
      component: {
        showLabel: true,
      },
      outputFormatter: ({ formattedLabel, rawValue, i18n }) => ({
        formattedLabel,
        formattedValue: (rawValue
          ? i18n.t('switch.yes')
          : i18n.t('switch.no')) as string,
      }),
    }),
  ],
  id: StepId.CONTACT_FORM,
  submit: async ({ steps, assistantContext }) => {
    try {
      await sendMail(steps as StepDefinition[], assistantContext, {
        contactData: getMarkdownData(steps, assistantContext),
      });
    } catch (e) {
      throw new Error('An error occurred while sending the mail.');
    }
  },
  type: StepTypes.DEFAULT,
};

export default config;
