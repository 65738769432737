import { FormFieldType } from '@/consts/input-types';
import { ValidationType } from '@/consts/validation-types';
import {
  FormField,
  FormUploadFieldComponentConfig,
} from '@/interfaces/step-definition';
import i18n from '@/plugins/i18n';
import {
  checkEmail,
  checkPhoneNumber,
  checkPostalCodeDE,
} from '@/lib/validators';

export const getValidators = (formField: FormField): Array<unknown> => {
  const validators: Array<unknown> = [];

  if (formField.required) {
    if (
      formField.type === FormFieldType.MultipleChoice ||
      (formField.type === FormFieldType.FileUpload &&
        (formField.component as FormUploadFieldComponentConfig).multiple)
    ) {
      // eslint-disable-next-line
      const checkRequired = (v: Array<any>) => {
        return v.length > 0 || i18n.t('validators.checkRequired');
      };
      validators.push(checkRequired);
    } else {
      // eslint-disable-next-line
      const checkRequired = (v: any) => !!v || i18n.t('validators.checkRequired');
      validators.push(checkRequired);
    }
  }

  if (formField.validation) {
    formField.validation.forEach((validator) => {
      if (typeof validator === 'object' && validator.validator !== undefined) {
        // eslint-disable-next-line
        const checkRequired = (v: any) => {
          const errorMessage =
            typeof validator.errorLabel === 'object'
              ? i18n.t(
                  'errorMessages.' + validator.errorLabel.label,
                  validator.errorLabel.namedFormatter,
                )
              : i18n.t('errorMessages.' + validator.errorLabel);
          return validator.validator(v) || errorMessage;
        };
        validators.push(checkRequired);
      }
      if (validator === ValidationType.numeric) {
        // eslint-disable-next-line
        const checkNumeric = (v: any) =>
          /^[0-9]+$/.test(v) || !v || i18n.t('validators.checkNumeric');
        validators.push(checkNumeric);
      }
      if (validator === ValidationType.email) {
        // eslint-disable-next-line
        const emailValidator = (v: any) => !v || checkEmail(v);
        validators.push(emailValidator);
      }
      if (validator === ValidationType.phoneNumber) {
        // eslint-disable-next-line
        const phoneNumberValidator = (v: any) => !v || checkPhoneNumber(v);
        validators.push(phoneNumberValidator);
      }
      if (validator === ValidationType.postalCodeDE) {
        // eslint-disable-next-line
        const postalCodeValidator = (v: any) => !v || checkPostalCodeDE(v);
        validators.push(postalCodeValidator);
      }
    });
  }
  return validators;
};
